import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout/Layout'
import Banner from '../components/Banner/Banner'
import Button from '../components/Button/Button'
import Title from '../components/Title/Title'
import Underline from '../components/Underline/Underline'
import * as FAQStyles from './faq.module.css'
import { faq } from '../util/data'

function FAQ() {
    return (
        <Layout>
            <Helmet>
                <title>FAQ | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <section className={FAQStyles.titleSection}>
                <Title>
                    <h1>Frequently Asked Questions</h1>
                </Title>
            </section>
            <section className={FAQStyles.grid}>
                <div className={FAQStyles.contentsContainer}>
                    <div className={FAQStyles.contentsBox}>
                        <h3>FAQ Contents</h3>
                        <ul>
                            {faq.map(faq => {
                                return (
                                    <li key={faq.id}>
                                        <a href={`#faq-${faq.id}`}>{faq.question}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className={FAQStyles.faqContainer}>
                    {
                        faq.map(faq => {
                            return (
                                <div id={`faq-${faq.id}`}>
                                    <Underline>
                                        <h2 style={{fontSize: "var(--type-l)"}}>{faq.question}</h2>
                                    </Underline>
                                    {faq.answer}
                                </div>
                            )
                        })
                    }
                </div>
            </section>
            <section className={FAQStyles.bannerSection}>
                <Banner>
                    <h2>Haven't found the answer to your question?</h2>
                    <Button external={false} to="/contact" type="secondaryOutline">Get in touch</Button>
                </Banner>
            </section>
        </Layout>
    )
}

export default FAQ